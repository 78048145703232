import React, {
  FC,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { differenceInYears } from 'date-fns';
import { AxiosError, AxiosResponse } from 'axios';
import './BookingWidget.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import i18n from 'i18next';
import useFetchBookingConf from './BookingConfig/useFetchBookingConf';
import {
  AdditionalDataEnum,
  Banner,
  BannerEnum,
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from './bookingSteps.interface';
import { LoadingContext } from '../../context/loadingContext/loadingContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import { BookingDataContext } from '../../context/bookingDataContext/bookingDataContext';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import useApi from '../../shared/services/api.service';
import {
  BookingDataResponse,
  Companion,
  CompanionEnum,
  Customer,
  CustomerEnum,
} from './BookingManagment/bookingManagment.interface';
import {
  BookingMeResponse,
  BookingMeResponseEnum,
} from './bookingMe.interface';
import {
  prepareInitInformationPersonal,
  prepareInitInformationTravellers,
} from '../QuotationInformationForm/quotation-information.helper';
import { isAgent, isAuthCustomer } from '../../shared/helpers/auth.helper';
import ErrorLoggingService from '../../shared/services/errorlogging.service';
import { AppConfigurationContext } from '../../context/appConfigurationContext/appConfigurationContext';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import { sendGTMEventPageChangeToParent } from '../../shared/services/gtm.service';
import { BookingContextState } from '../../context/bookingContext/bookingContext.types';
import contextDefaultValues from '../../context/bookingContext/bookingContext.const';
import useSummaryVisible from '../../shared/helpers/useSummaryVisible';
import mapToCreateQuoteResponse from './BookingQuoteManagment/bookingQuoteMaper';

import { PanelRightVisibleForComponent } from '../../shared/consts/app.const';
import SpinnerStatic from '../../shared/components/Loading/SpinnerStatic';
import getBookingStepIndex from '../../shared/helpers/getBookingStepIndex.helper';
import useCardUX from '../../shared/helpers/cardUx.helper';
import { FormQuotationInformationEnum } from '../QuotationInformationForm/quoation-information.interface';

const PanelRight = React.lazy(() => import('../PanelRight/PanelRight'));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BookingWidgetProps {}

const getComponent = async (componentName: string): Promise<any> => {
  switch (componentName) {
    case 'QuotationPreliminaryDeclarations':
      return import(
        '../QuotationPreliminaryDeclarations/QuotationPreliminaryDeclarations'
      );
    case 'QuotationForm':
      return import('../QuotationForm/QuotationForm');
    case 'QuotationFormWeb':
      return import('../QuotationFormWeb/QuotationFormWeb');
    case 'QuotationConfirmation':
      return import('../QuotationConfirmation/QuotationConfirmation');
    case 'QuotationInformationForm':
      return import('../QuotationInformationForm/QuotationInformationForm');
    case 'QuotationMedicalScreening':
      return import('../QuotationMedicalScreening/QuotationMedicalScreening');
    case 'QuotationProposals':
      return import('../QuotationProposals/QuotationProposals');
    case 'QuotationProposalsAddons':
      return import(
        '../QuotationProposals/components/QuotationProposalsAddons'
      );
    case 'QuotationProposalsOptions':
      return import(
        '../QuotationProposals/components/QuotationProposalsOptions'
      );
    case 'QuotationRecapDeclarations':
      return import('../QuotationRecapDeclarations/QuotationRecapDeclarations');
    default:
      return null;
  }
};
// eslint-disable-next-line react/function-component-definition
const BookingWidget: FC<BookingWidgetProps> = () => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const [activeStepIndex, setActiveStepIndex] = useState(1);
  const { showNotification } = useContext(NotificationContext);
  const [closedBanners, setClosedBanners] = useState<string[]>([]);
  const [banner, setBanner] = useState<Banner>(null!);
  const { t } = useTranslation();
  const { setIsLoading } = useContext(LoadingContext);
  const { appConfiguration, updateConfiguration } = useContext(
    AppConfigurationContext,
  );
  const { bookingSteps, loadQuote, loadBooking, update } =
    useContext(BookingContext);
  const latestBookingSteps = useRef<BookingContextState>(contextDefaultValues);
  const bookingParams = '';
  const { bookingConfig, error } = useFetchBookingConf<BookingConfig>(
    bookingParams,
    setIsLoading,
  );

  const activeStep =
    bookingConfig?.[BookingConfigEnum.BookingSteps]?.[activeStepIndex];
  const isSummaryVisible = useSummaryVisible(activeStep);
  const { updateBookingData } = useContext(BookingDataContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { updateQuote } = useContext(BookingQuoteContext);
  const quoteId: string | null = getUrlSearchParam('quoteId', urlParams);
  const externalBookingId: string | null = getUrlSearchParam(
    'externalBookingId',
    urlParams,
  );
  const accessTokenParam: string | null = getUrlSearchParam('token', urlParams);
  const isTransparentBg: string | null = getUrlSearchParam(
    'transparent',
    urlParams,
  );
  const bookingDataId: string | null = getUrlSearchParam(
    'bookingDataId',
    urlParams,
  );
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const isMobileView = useMediaQuery('(max-width:600px)');
  const { isCardUX } = useCardUX(true);
  const API = useApi(bookingConfigData);
  useEffect((): void => {
    // @ts-ignore @TODO fix this, should be without error since we are using ref
    latestBookingSteps.current = bookingSteps;
  }, [bookingSteps]);

  const getExternalBooking = async (id: string): Promise<any> => {
    // @TODO mock data, should be from different endpoint - currently not known
    const contextToBeUpdated = {
      tripDetails: {
        value: 'FCTG',
        label: '',
      },
      geographicalZone: {
        value: 'DOMESTIC',
        label: 'Domestic',
      },
      departureDate: '2024-06-29T13:25:48.000Z',
      returnDate: '2024-07-31T13:25:48.000Z',
      travellersType: {
        label: 'Individual(s)',
        value: 'individual',
      },
      travellersAge: ['33'],
      maxTripDuration: {
        value: '',
        label: '',
      },
    };
    return contextToBeUpdated;
  };

  const getBooking = async (
    id: string,
  ): Promise<{
    bookingResponse: BookingDataResponse;
    quoteToken: string;
  } | void> => {
    try {
      const url = `${apiCM360Url}/booking/${id}`;
      const bookingRes: AxiosResponse = await API.get(url);
      if (bookingRes.status !== 200) {
        showNotification('unexpectedError', 'error', false);
      }
      if (bookingRes.headers['x-amzn-remapped-authorization']) {
        localStorage.setItem(
          'token',
          bookingRes.headers['x-amzn-remapped-authorization'].replace(
            'Bearer ',
            '',
          ),
        );
      }
      return {
        bookingResponse: bookingRes.data,
        quoteToken: bookingRes.headers['x-amzn-remapped-authorization'],
      };
    } catch (e) {
      setIsLoading(false);
      if (e instanceof AxiosError && !!e.response) {
        if (e.response.status === 404) {
          return showNotification('expiredLink', 'error', false);
        }
      }
      return showNotification('unexpectedError', 'error', false);
    }
  };

  const getQuote = async (
    id: string,
    bookingResponse: any,
    quoteToken: string,
  ): Promise<any> => {
    try {
      const quoteUrl = `${apiCM360Url}/ui-proxy/ws-partners/api/platforms/${
        bookingConfigData[BookingConfigEnum.DataCenter].psPlatform
      }/catalogs/${bookingResponse.bookingData.catalog}/quotes/${id}`;
      let requestOptions = {};
      if (quoteToken) {
        requestOptions = {
          headers: {
            Authorization: `Bearer ${quoteToken}`,
            'Client-Id':
              bookingConfigData[BookingConfigEnum.DataCenter].psClient,
          },
        };
      } else {
        requestOptions = {
          headers: {
            'Client-Id':
              bookingConfigData[BookingConfigEnum.DataCenter].psClient,
          },
        };
      }
      const quoteResponse: AxiosResponse = await API.get(
        quoteUrl,
        requestOptions,
      );
      if (quoteResponse.status !== 200) {
        showNotification('unexpectedError', 'error', false);
      }
      const quoteResult = await quoteResponse.data;
      const { companions } = bookingResponse;
      const medicalConditionsRequests: Promise<any>[] = [];
      if (companions.some((c: any) => c.token)) {
        companions.forEach((c: any, i: number) => {
          const medicalToken = c.token;
          if (!medicalToken) {
            return;
          }
          const medicalConditionsUrl = `${apiCM360Url}/medical-screenings/${medicalToken}?filter=isCovered,declarations,conditions`;
          const medicalConditionsResponse: any = API.get(
            medicalConditionsUrl,
          ).then((res: any) => {
            if (res.status !== 200) {
              showNotification('unexpectedError', 'error', false);
            }
            const result = { ...res.data };
            if (result.isCovered === undefined || result.isCovered === null) {
              const QuotationInformationStepIndex = getBookingStepIndex(
                bookingConfig,
                BookingStepValueEnum.QuotationInformation,
              );
              result.declarations = (
                bookingConfig[BookingConfigEnum.BookingSteps][
                  QuotationInformationStepIndex
                ].cards[0]?.options as any
              )
                .filter(
                  (o: any) =>
                    o.label.includes('medicalCondition') ||
                    o.value.includes('medicalCondition') ||
                    o.label.includes('extraQuestion') ||
                    o.value.includes('extraQuestion'),
                )
                .map((o: any, index: number) => ({
                  orderId: index + 1,
                  level: index < 3 ? 1 : 2,
                  question: o.description,
                  answer: 'No',
                }));
            }
            return result;
          });
          medicalConditionsRequests[i] = medicalConditionsResponse;
        });
        const medicalConditionsResult = await Promise.all(
          medicalConditionsRequests,
        );
        return {
          quoteResult,
          medicalConditionsResult,
        };
      }
      return {
        quoteResult,
        medicalConditionsResult: companions.map((c: any) => null),
      };
    } catch (e) {
      return showNotification('unexpectedError', 'error', false);
    }
  };

  const getInitialCustomersData =
    async (): Promise<BookingMeResponse | void> => {
      try {
        const url = `${apiCM360Url}/booking/me`;
        const customerDataRes: AxiosResponse = await API.get(url);
        if (customerDataRes.status !== 200) {
          showNotification('unexpectedError', 'error', false);
        }
        return customerDataRes.data;
      } catch (err) {
        errorService.log('getInitialCustomersData', err);
        localStorage.removeItem('token');
        return null!;
      }
    };

  const sendGtmEvent = (stepNumber: number): void => {
    sendGTMEventPageChangeToParent(
      'step-change',
      bookingConfig[BookingConfigEnum.BookingSteps][stepNumber][
        BookingStepEnum.KeyName
      ],
    );
  };

  const [ActiveComponent, setActiveComponent] =
    useState<React.ComponentType<any> | null>(null);
  const loadComponent = async (step?: number): Promise<void> => {
    const stepKey =
      step !== null && step !== undefined ? step : activeStepIndex;
    if (bookingConfig) {
      const filteredObject = bookingConfig[
        BookingConfigEnum.BookingSteps
      ].filter(
        (bookingConfigItem) =>
          bookingConfigItem[BookingStepEnum.KeyName] ===
          bookingSteps[BookingStepValueEnum.CurrentStep],
      );
      const componentName =
        filteredObject.length > 0 && !step
          ? filteredObject[0][BookingStepEnum.Component]
          : (
              bookingConfig[BookingConfigEnum.BookingSteps]?.[
                stepKey
              ] as BookingStep
            ).component;
      if (!isCardUX && componentName === 'QuotationForm') {
        const QuotationFormWeb = (
          await import('../QuotationFormWeb/QuotationFormWeb')
        ).default;
        setActiveComponent(() => QuotationFormWeb);
      } else {
        const componentModule = await getComponent(componentName);
        if (componentModule) {
          setActiveComponent(() => componentModule.default);
        }
      }
    }
  };

  useEffect((): void => {
    loadComponent();
  }, [isCardUX]);

  const setResumedStep = (step: string): void => {
    const stepIndex = bookingConfigData.bookingSteps.findIndex(
      (s) => s.keyName === step,
    );
    if (stepIndex > -1 && activeStepIndex !== stepIndex) {
      if (stepIndex === 0 && !isAgent()) {
        setActiveStepIndex(1);
        sendGtmEvent(1);
      } else {
        setActiveStepIndex(stepIndex);
        loadComponent(stepIndex);
        sendGtmEvent(stepIndex);
      }
    }
  };

  const getApplicationConfig = async (): Promise<void> => {
    try {
      const timestamp = Date.now();
      const configUrl = `/config/config_default.json?timestamp=${timestamp}`;
      const response = await fetch(configUrl);
      if (!response.ok) {
        showNotification('unexpectedError', 'error', false);
      }
      const fetchedData = await response.json();
      updateConfiguration(fetchedData.data);
      return await fetchedData.data;
    } catch (e) {
      return showNotification('unexpectedError', 'error', false);
    }
  };

  const loadSavedData = async (): Promise<void> => {
    setIsLoading(true);
    const id = quoteId || bookingDataId;
    const bookingResponseData = await getBooking(id!);
    if (bookingResponseData && bookingResponseData.bookingResponse) {
      const { bookingResponse, quoteToken } = bookingResponseData;
      updateBookingData(bookingResponse);

      if (quoteId) {
        console.log('---------', quoteToken);
        const { quoteResult, medicalConditionsResult } = await getQuote(
          quoteId,
          bookingResponse,
          quoteToken,
        );
        updateQuote(
          mapToCreateQuoteResponse(quoteId, quoteResult, accessTokenParam),
        );
        loadQuote({
          quote: quoteResult,
          medicalConditions: medicalConditionsResult,
          consents: bookingResponse.consents,
          bookingResponse,
          latestBookingSteps: latestBookingSteps.current,
        });
        setResumedStep(bookingResponse.bookingData.currentStep);
      } else {
        loadBooking(bookingResponse);
        setActiveStepIndex(2);
      }
      setIsLoading(false);
    }
  };

  const checkBanner = (step: number): void => {
    const storedClosedBanners = localStorage.getItem('closedBanners');
    if (storedClosedBanners) {
      setClosedBanners(JSON.parse(storedClosedBanners));
    }
    if (bookingConfigData.bookingSteps[step][BookingStepEnum.Banner]) {
      setBanner(
        bookingConfigData.bookingSteps[step][BookingStepEnum.Banner] as Banner,
      );
    }
  };

  const updateInitialCustomersData = async (): Promise<void> => {
    let customerResponse: BookingMeResponse | undefined | void;
    if (apiCM360Url) {
      customerResponse = await getInitialCustomersData();
    }
    if (customerResponse && !Array.isArray(customerResponse)) {
      const customer: Customer =
        customerResponse[BookingMeResponseEnum.Customer];
      const companions: Companion[] =
        customerResponse[BookingMeResponseEnum.Companions];
      const companionsAge: string[] = [];

      companionsAge.push(
        differenceInYears(
          new Date(),
          new Date(customer[CustomerEnum.DateOfBirth]),
        ).toString(),
      );
      companions.map((companion) =>
        companionsAge.push(
          differenceInYears(
            new Date(),
            new Date(companion[CompanionEnum.DateOfBirth]),
          ).toString(),
        ),
      );
      if (bookingSteps.currentStep === 'quotationForm') {
        update(
          companionsAge,
          BookingStepValueEnum.QuotationForm,
          BookingSubStepValueEnum.TravellersAge,
        );
      }

      const preparedPersonalInformation =
        prepareInitInformationPersonal(customer);
      const quotationInformationStepIndex = getBookingStepIndex(
        bookingConfigData,
        BookingStepValueEnum.QuotationInformation,
      );
      const statesConfig =
        bookingConfigData[BookingConfigEnum.BookingSteps][
          quotationInformationStepIndex
        ][BookingStepEnum.AdditionalData]?.[AdditionalDataEnum.StatesConfig];
      if (
        statesConfig?.isEnabled &&
        !statesConfig.states.find(
          (s: any) =>
            s.code ===
            preparedPersonalInformation[FormQuotationInformationEnum.State],
        )
      ) {
        preparedPersonalInformation[FormQuotationInformationEnum.State] = '';
      }
      update(
        preparedPersonalInformation,
        BookingStepValueEnum.QuotationInformation,
        BookingSubStepValueEnum.InformationPersonal,
      );

      const travellersAge =
        bookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TravellersAge
        ];
      if (
        bookingSteps.currentStep === 'quotationProposals' &&
        travellersAge.length > 1
      ) {
        update(
          prepareInitInformationTravellers(
            companions.slice(0, travellersAge.length - 1),
          ),
          BookingStepValueEnum.QuotationInformation,
          BookingSubStepValueEnum.InformationTravellers,
        );
      }
    }
  };

  const handleNext = (step?: number): void => {
    if (typeof step === 'number') {
      loadComponent(step);
      sendGtmEvent(step);
      setActiveStepIndex(step);
      window.scrollTo(0, 0);
      window.parent.postMessage({ type: 'SCROLL_TO_TOP' }, '*');
      checkBanner(activeStepIndex);
      return;
    }
    if (
      activeStepIndex <
      bookingConfig[BookingConfigEnum.BookingSteps].length - 1
    ) {
      loadComponent(activeStepIndex + 1);
      checkBanner(activeStepIndex + 1);
      setActiveStepIndex((prevStep) => prevStep + 1);
      sendGtmEvent(activeStepIndex + 1);
      window.scrollTo(0, 0);
      window.parent.postMessage({ type: 'SCROLL_TO_TOP' }, '*');
    }
  };

  const loadExternalBookingData = async (): Promise<void> => {
    setIsLoading(true);
    const bookingResponse = await getExternalBooking(externalBookingId!);
    // @TODO skip for now, we need to know how get this booking
    // update(bookingResponse, BookingStepValueEnum.QuotationForm);
    setResumedStep('quotationProposals');
    setIsLoading(false);
    // handleNext();
  };

  const loadGlassboxScript = (): void => {
    const glassboxUrl = bookingConfig[BookingConfigEnum.GlassboxUrl];
    if (glassboxUrl) {
      const scriptDetector = document.createElement('script');
      scriptDetector.id = '_cls_detector';
      scriptDetector.src = glassboxUrl;
      document.head.appendChild(scriptDetector);
    }
  };

  useEffect((): void => {
    if (bookingConfig) {
      loadComponent();
      loadGlassboxScript();
    }
    if (isAuthCustomer()) {
      updateInitialCustomersData();
    }
    if (externalBookingId && bookingConfigData.bookingSteps.length) {
      loadExternalBookingData();
    } else if (
      (quoteId || bookingDataId) &&
      bookingConfigData.bookingSteps.length
    ) {
      loadSavedData();
    } else {
      setResumedStep(bookingSteps[BookingStepValueEnum.CurrentStep]);
    }

    if (!appConfiguration) {
      getApplicationConfig();
    }

    // Used on IGO website
    if (isTransparentBg) {
      document.body.classList.add('bg-transparent');
    } else {
      document.body.classList.remove('bg-transparent');
    }

    if (bookingConfigData.bookingSteps.length > 0) {
      checkBanner(activeStepIndex);
    }

    if (bookingConfigData[BookingConfigEnum.Locale]) {
      i18n.changeLanguage(bookingConfigData[BookingConfigEnum.Locale]);
    }
  }, [bookingConfigData]);

  const closeBanner = (bannerId: string): void => {
    const updatedClosedBanners = [...closedBanners, bannerId];
    setClosedBanners(updatedClosedBanners);
    localStorage.setItem('closedBanners', JSON.stringify(updatedClosedBanners));
  };

  if (error) {
    return (
      <Alert className="error-loading" severity="error">
        {t('COMMON.errorLoading')}
      </Alert>
    );
  }
  if (!bookingConfig) {
    return null;
  }

  const handlePrevious = (): void => {
    loadComponent(activeStepIndex - 1);
    checkBanner(activeStepIndex - 1);
    setActiveStepIndex((prevStep) => prevStep - 1);
    window.scrollTo(0, 0);
    window.parent.postMessage({ type: 'SCROLL_TO_TOP' }, '*');
  };

  if (!ActiveComponent) {
    return <SpinnerStatic />;
  }

  return (
    <Container className="main-container" data-testid="BookingWidget">
      {banner && !closedBanners.includes(banner[BannerEnum.Id]) && (
        <div className="banner">
          <div className="banner__title">{banner[BannerEnum.Content]}</div>
          <div className="banner__close">
            <HighlightOffIcon
              className="close-icon"
              onClick={() => closeBanner(banner[BannerEnum.Id])}
            />
          </div>
        </div>
      )}

      {bookingConfig[BookingConfigEnum.BookingSteps].length > 0 && (
        <div className="booking-container">
          <div
            className={`booking-component ${
              isSummaryVisible && isMobileView ? 'with-quote' : ''
            }`}
          >
            <ActiveComponent
              bookingConfig={bookingConfig}
              activeStepConfig={activeStep}
              keyName={
                bookingConfig[BookingConfigEnum.BookingSteps][activeStepIndex][
                  BookingStepEnum.KeyName
                ]
              }
              cards={
                bookingConfig[BookingConfigEnum.BookingSteps][activeStepIndex][
                  BookingStepEnum.Cards
                ]
              }
              nextStep={handleNext}
              previousStep={handlePrevious}
            />
          </div>
          {PanelRightVisibleForComponent.includes(
            (
              bookingConfig[BookingConfigEnum.BookingSteps][
                activeStepIndex
              ] as BookingStep
            )[BookingStepEnum.Component],
          ) && (
            <Suspense fallback={<div>Loading...</div>}>
              <PanelRight
                nextStep={handleNext}
                activeStep={
                  bookingConfig[BookingConfigEnum.BookingSteps][
                    activeStepIndex
                  ] as BookingStep
                }
              />
            </Suspense>
          )}
        </div>
      )}
    </Container>
  );
};

export default BookingWidget;
